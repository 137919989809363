import axios from "axios";

const PRODUCTION = process.env.REACT_APP_PRODUCTION === "TRUE";

const STAGE_URL = process.env.REACT_APP_STAGE_URL;

const BASE_URL = PRODUCTION ? process.env.PRODUCTION_URL : STAGE_URL;

export const googleAuth = async (params) => {
  try {
    const response = await axios.post(`${BASE_URL}/google-signin`, params);

    return response;
  } catch (error) {
    return error.response;
  }
};
